<header class="header" xmlns="http://www.w3.org/1999/html">
	<div class="wrapper">
		<div class="logo-container">
			<div class="logo-wrapper">
				<a href="https://{{bnmDomain}}">
					<img src="/assets/images/header/bn-logo-powered-3.png" class="logo"/>
				</a>
			</div>
		</div>
<!--		<div class="nav-container">-->
<!--			<a class="nav-link" href="https://{{bnmDomain}}/renewal"><span dirMngDefaultTracker="renewal">Renewal</span></a>-->
<!--			<a class="nav-link" href="https://{{bnmDomain}}/transfer"><span dirMngDefaultTracker="transfer">Transfer</span></a>-->
<!--			<a class="nav-link" href="https://{{bnmDomain}}/pricing"><span dirMngDefaultTracker="pricing">Pricing</span></a>-->
<!--			<a class="nav-link" href="https://{{bnmDomain}}/contact-us"><span dirMngDefaultTracker="contact">Contact Us</span></a>-->
<!--			<a class="nav-link phone" href="tel:1300320286">-->
<!--				<img class="phone-icon" alt="" src="/assets/images/main/phone-icon.png">1300 320 286-->
<!--			</a>-->
<!--		</div>-->
<!--		<div class="nav-mobile">-->
<!--		<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--			<mat-icon>view_headline</mat-icon>-->
<!--		</button>-->
<!--		<mat-menu #menu="matMenu" class="nav-menu-panel">-->
<!--			<button mat-menu-item>-->
<!--				<a class="nav-link" href="https://{{bnmDomain}}/renewal"><span dirMngDefaultTracker="renewal">Renewal</span></a>-->
<!--			</button>-->
<!--			<button mat-menu-item>-->
<!--				<a class="nav-link" href="https://{{bnmDomain}}/transfer"><span dirMngDefaultTracker="transfer">Transfer</span></a>-->
<!--			</button>-->
<!--			<button mat-menu-item>-->
<!--				<a class="nav-link" href="https://companyregistration.com.au/"><span dirMngDefaultTracker="company_registration">Company Registration</span></a>-->
<!--			</button>-->
<!--			<button mat-menu-item>-->
<!--				<a class="nav-link" href="https://{{bnmDomain}}/pricing"><span dirMngDefaultTracker="pricing">Pricing</span></a>-->
<!--			</button>-->
<!--			<button mat-menu-item [routerLink]="['/contact-us']">-->
<!--				<a class="nav-link" href="https://{{bnmDomain}}/contact-us"><span dirMngDefaultTracker="contact">Contact Us</span></a>-->
<!--			</button>-->
<!--			<button mat-menu-item>-->
<!--				<a class="nav-phone" href="tel:1300320286">-->
<!--					<img class="phone-icon" alt="" src="/assets/images/main/phone-icon.png" style="padding-right: 10px">-->
<!--					<p>1300 320 286</p>-->
<!--				</a>-->
<!--			</button>-->
<!--		</mat-menu>-->
<!--		</div>-->
	</div>
</header>
