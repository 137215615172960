import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCustomPreloader } from './app-custom-preloader';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'phone-inquiry',
    pathMatch: 'full',
    loadChildren: () => import('@app/slack/slack.module').then(m => m.SlackModule)
  },
  {
    path: 'unsubscribe',
    pathMatch: 'full',
    loadChildren: () => import('@app/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: 'error',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: AppCustomPreloader,
  })],
  providers: [AppCustomPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule{}
