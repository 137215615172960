import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from "@env/environment";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  bnmDomain:string;


  constructor(
  ) {
    this.bnmDomain = environment.bnmDomain;
  }

  ngOnInit(): void {
  }
}
