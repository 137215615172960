
/* tslint:disable */
export const environment = {
  production: true,
  protocol: 'https',
  dspDomain: 'domain.businesssname.com.au',
  lambdaDomain: 'https://hf4nviiy80.execute-api.ap-southeast-2.amazonaws.com/domain/email?token=',
  bnmDomain: 'businessname.com.au',
    livechat: {
    enabled: true,
    min: 0,
    max: 100000,
    license: 3817061,
  },
  version: '1.0.0',
};
/* tslint:enable */
