import { Component, ChangeDetectorRef } from '@angular/core';
import { Store } from '@app/services/store.service';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import { Action } from '@app/services/action';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dns';
  showGlobalPreloader = false;
  hideFooter = false;
  trackCategory = '';

  constructor(
     private _store: Store,
     private _changeDetectorRef: ChangeDetectorRef,
     private _router: Router,
     private _title: Title,
     private _meta: Meta
  ) {}

  ngOnInit(): void {
    this._title.setTitle('Business Name Australia | BusinessName.com.au');
    this._meta.addTags([
      {
        name: 'keywords',
        content: 'business name, register business name, business name registration, register a business, registering a business' +
            ' name, business name australia',
      },
      {
        name: 'description',
        content: 'Fastest business name registration service. Trusted by over 500,000 Australians. Register Now!'
      },
      {name: 'dc.title', content: 'Business Name Registration Australia | BusinessName.com.au'},
      {
        name: 'dc.description',
        content: 'Fastest business name registration service. Trusted by over 500,000 Australians. Register Now!'
      },
      {
        name: 'dc.keywords',
        content: 'business name, register business name, business name registration, register a business, registering a business name,' +
            ' business name australia',
      },
      {name: 'geo.placename', content: 'Australia'},
      {name: 'geo.position', content: '-33.87055; 151.20596'},
      {name: 'geo.region', content: 'au'},
    ]);
    this.initStoreEvents();
    this.initRouteEvents();
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  private initStoreEvents(): void {
    this._store.select('layoutShowPreloader').subscribe((show: boolean) => {
      this.showGlobalPreloader = show;
    });

    this._store.select('layoutHideFooter').subscribe((hide: boolean) => {
      this.hideFooter = hide;
    });
  }

  private initRouteEvents(): void {

  }

  private resetLayoutDefaults(): void {
    this._store.dispatch(new Action('UPDATE', { layoutShowPreloader: false }));
    this._store.dispatch(new Action('UPDATE', { layoutHideFooter: true }));
  }
}
