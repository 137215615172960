<div class="footer">
	<div class="footer__details" *ngIf="!hideMainFooter">
		<div class="footer__details--wrapper">
			<div class="details-about">
				<h3>ABOUT CBD ADDRESS</h3>
				<a href="/learn-more"><span dirMngDefaultTracker="learn_more">Learn More</span></a>
				<a href="/contact-us"><span dirMngDefaultTracker="contact-us">Contact Us</span></a>
				<a href="/pricing"><span dirMngDefaultTracker="pricing">Pricing</span></a>
				<a href="/terms-conditions"><span dirMngDefaultTracker="terms">Terms of Use</span></a>
				<a href="/privacy-policy"><span dirMngDefaultTracker="privacy">Privacy Policy</span></a>
			</div>
			<div class="details-business-services">
				<h3>BUSINESS SERVICES</h3>
				<a href="https://businessname.com.au" target="_blank"><span dirMngDefaultTracker="bn_reg">Business Name Registration</span></a>
				<a href="https://businessname.com.au/renewal" target="_blank"><span dirMngDefaultTracker="bn_renewal">Business Name Renewal</span></a>
				<a href="https://transfer.businessname.com.au/" target="_blank"><span dirMngDefaultTracker="bn_transfer">Business Name Transfer</span></a>
				<a href="https://companyregistration.com.au" target="_blank"><span dirMngDefaultTracker="com_reg">Company Registration</span></a>
				<a href="https://abnregistration.com.au/" target="_blank"><span dirMngDefaultTracker="abn_reg">ABN Registration</span></a>
				<a href="https://trademarkregistration.com.au/" target="_blank"><span dirMngDefaultTracker="tm_reg">Trademark Registration</span></a>
			</div>
			<div class="details-web-services">
				<h3>WEB SERVICES</h3>
				<a target="_blank" href="https://www.honcho.com.au/domain"><span dirMngDefaultTracker="dn">Domain Name</span></a>
				<a target="_blank" href="https://www.honcho.com.au/website/"><span dirMngDefaultTracker="web">Website</span></a>
			</div>
			<div class="details-contact">
				<h3>CONTACT US</h3>
				<div class="row">
					<img src="/assets/images/main/phone-1.png"><a href="tel:0283112822">02 8311 2822</a>
				</div>
				<div class="row">
					<img src="/assets/images/main/envelope-1.png"><a href="mailto:contact@cbdaddress.com.au">contact@cbdaddress.com.au</a>
				</div>
				<div class="row">
					<img src="/assets/images/main/access_time.png"><a href="javascript:;">Monday - Friday 9AM - 8PM AEST</a>
				</div>
			</div>
		</div>
		<div class="footer__details--disclaimer">
			Business Switch Pty Ltd (ACN ‌134 ‌235 ‌304) is an Australian business specialising in business registration and essential business services. Founded in 2008, the company is a registered ASIC agent and ASIC Digital Services Provider (DSP) and is also a Registered Tax Agent and ATO Digital Services Provider. The business is not affiliated with the Commonwealth Government. Using the latest in technological innovation and superior customer service the company has helped more than 500,000 Australians to start and operate their business.
		</div>
	</div>

	<div class="footer__copyright">
		<div class="footer__copyright--wrapper">
			<div class="footer__copyright--text">© {{ yearDate }} Businessname.com.au. All rights reserved.</div>
			<div class="footer__copyright--details">ABN: 83 134 235 304 | ACN: 134 235 304</div>
		</div>
	</div>
</div>

